$(document).ready(function () {
    var $j = jQuery.noConflict(); // Use
    // jQuery com a variavel $j(...)
    $j('a[href^="#"]').on('click', function (event) {
        //definimos que qualquer link usando hash no href
        var target = $j($j(this).attr('href'));
        if (target.length) {
            event.preventDefault();
            $j('html, body').animate({scrollTop: target.offset().top}, 1000);
            //1000 é a velocidade da animação
        }
    });
});

